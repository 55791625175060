import { useRef, useMemo, useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSearchBox } from 'react-instantsearch';
import { debounce } from 'throttle-debounce';

import { Icon } from 'components/elements/atoms/icon/Icon';
import { useFormatMessage } from 'utilities/i18n';
import { useSiteDataFetcher } from 'api/requests/site/site';
import { sendAmplitudeLog } from 'components/app/analytics/amplitude/Amplitude.utils';
import { AmplitudeEvent } from 'components/app/analytics/amplitude/Amplitude.types';
import { AllAccessYearlyBanner, BannerType } from 'api/requests/site/site.types';
import { facebookEvent } from 'components/app/analytics/facebookPixel/FacebookPixel.utils';
import { FacebookEventName } from 'components/app/analytics/facebookPixel/FacebookPixel.types';

export function SearchInput() {
  const router = useRouter();
  const t = useFormatMessage();
  const { refine, query } = useSearchBox();

  const { data } = useSiteDataFetcher();
  const { banner } = data || {};
  const inputRef = useRef<HTMLInputElement>(null);

  function sendEvent() {
    let bannerDisplayed = false;

    if (
      banner &&
      (banner?.type === BannerType.ALL_ACCESS_YEARLY || banner?.type === BannerType.ALL_ACCESS_YEARLY_EXTEND)
    ) {
      bannerDisplayed = true;
    }

    sendAmplitudeLog(AmplitudeEvent.PRODUCTS_SEARCHED, {
      query: inputRef.current?.value ?? '',
      banner_above_nav: bannerDisplayed ? (banner as AllAccessYearlyBanner)?.banner_above_nav : '',
      banner_above_nav_state: bannerDisplayed ? (banner as AllAccessYearlyBanner)?.banner_above_nav_state : '',
    });

    facebookEvent(FacebookEventName.SEARCH, {
      search_string: inputRef.current?.value ?? '',
    });
  }

  const handleSearch = useCallback(function handleSearch() {
    refine(inputRef.current?.value ?? '');
    sendEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearch();
    }
  }
  useEffect(() => {
    let oldQuery = router.query.query || '';
    const handleRouteChange = (url: string) => {
      const urlParams = new URLSearchParams(new URL(url, window.location.origin).search);
      const newQuery = urlParams.get('query') || '';

      if (oldQuery !== newQuery) {
        inputRef.current?.focus();
        oldQuery = newQuery;
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events]);

  const debouncedCallback = useMemo(() => {
    return debounce(300, handleSearch);
  }, [handleSearch]);

  function handleChange() {
    debouncedCallback();
  }

  return (
    <>
      <div className="relative flex w-full flex-col">
        <div className="relative flex w-full items-center">
          <input
            ref={inputRef}
            onChange={handleChange}
            className="placeholder-transition h-[40px] w-full rounded-[6px] border border-gray-300 bg-gray-100 px-5 py-[10px] pr-10 text-gray-700 placeholder:text-gray-700 focus:outline-none"
            type="text"
            placeholder={t('search.placeholder')}
            onKeyPress={handleKeyPress}
            defaultValue={query}
          />

          <div
            onClick={handleSearch}
            className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3 text-gray-700"
          >
            <Icon size="lg" aria-label="search" icon="search" />
          </div>
        </div>
      </div>
    </>
  );
}
