import { useState } from 'react';

import { Icon } from 'components/elements/atoms/icon/Icon';
import fire from 'assets/icons/fire.gif';
import fireYellow from 'assets/icons/fire_yellow.gif';
import { FutureImage } from 'components/elements/atoms/image/FutureImage';

import * as S from './SparkIgniteButton.styles';
import { SparkIgniteButtonProps } from './SparkIgniteButton.types';

export function SparkIgniteButton({
  children,
  state,
  color = 'secondary',
  size = 'large',
  ...rest
}: SparkIgniteButtonProps) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <S.StyledButton
      color={color}
      size={size}
      state={state}
      fullWidth={true}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      type="submit"
      {...rest}
    >
      <S.Inner
        style={{
          // only left padding, as much as the icon width is
          paddingLeft: 16,
        }}
      >
        {/* the icon */}
        <S.IconWrapper
          style={{
            // the way it animates in, is from the left %0
            // to the left, minus half of the width of the container.
            // this is safe to do since we know the width of the icon
            // is not any more than 14px. if we change the item,
            // we need to change this value as well.
            width: 16,
            left: state === 'idle' ? `0%` : `calc(50% - 8px + 6px)`,
            marginLeft: -6,
          }}
        >
          <Icon
            icon={['fas', 'fire-flame-curved']}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              transition: 'opacity 0.5s cubic-bezier(.17,.67,.1,.99)',
              opacity: isHovered || state === 'processing' ? 0 : 1,
              fontSize: 16,
            }}
          />
          {/*
                  there needs to be a white flame when processing,
                  and a yellow flame on hover whilst in the idle state
              */}
          {state === 'processing' ? (
            <FutureImage src={fire} alt="" width={16} height={16} style={{ transform: 'translateY(-1px)' }} />
          ) : (
            <FutureImage
              src={fireYellow}
              alt=""
              width={16}
              height={16}
              style={{
                transform: 'translateY(-1px)',
                transition: 'opacity 1s cubic-bezier(.17,.67,.1,.99)',
                opacity: isHovered ? 1 : 0,
              }}
            />
          )}
        </S.IconWrapper>

        {/* the text */}
        <span
          style={{
            opacity: state === 'idle' ? 1 : 0,
            transition: 'opacity 0.25s cubic-bezier(.17,.67,.1,.99)',
          }}
        >
          {children}
        </span>
      </S.Inner>
    </S.StyledButton>
  );
}
