import { useCallback, useEffect } from 'react';

export function useInifiteScrollTrigger({
  ref,
  fetchNextPage,
  hasNextPage,
}: {
  ref: React.MutableRefObject<HTMLDivElement | undefined>;
  fetchNextPage: VoidFunction;
  hasNextPage?: boolean;
}) {
  const handleObserver: IntersectionObserverCallback = useCallback(
    entries => {
      const [target] = entries;

      if (target.isIntersecting && hasNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage],
  );

  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    const element = ref.current;
    const option = { threshold: 0 };
    const observer = new IntersectionObserver(handleObserver, option);
    observer.observe(element);

    return () => observer.unobserve(element);
  }, [fetchNextPage, handleObserver, ref, hasNextPage]);
}
