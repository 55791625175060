import { useFormatMessage } from 'utilities/i18n';
import { Link } from 'components/elements/atoms/link/Link';
import { AppRoute } from 'components/app/App.types';
import { useSiteDataFetcher } from 'api/requests/site/site';
import { useOptionalUser } from 'hooks/useAuthState/useAuthState';

export function FreeTrialBanner() {
  const t = useFormatMessage();
  const { data } = useSiteDataFetcher();
  const user = useOptionalUser();

  const enableStripeBilling = Boolean(data?.billing) && user !== null;

  const handlePaymentsModal = () => {
    if (typeof window.cfOpenPaymentsDialog === 'function') {
      window.cfOpenPaymentsDialog();
    }
  };

  return (
    <div className="flex w-full flex-col items-center justify-between gap-[10px] border border-border-300 bg-[#C8BBFF] p-[10px] shadow md:flex-row">
      <span className="ml-[10px] text-base font-bold text-black-500">{t('page.search.trial_product_card.title')}</span>
      {!enableStripeBilling ? (
        <Link
          underline="none"
          href={Boolean(data?.billing) ? AppRoute.B_FREE_TRIAL : AppRoute.FREE_TRIAL}
          color="inherit"
          className="flex h-[30px] w-full items-center justify-center rounded bg-primary-500 px-5 text-sm font-bold uppercase text-white hover:bg-primary-700 md:h-[40px] md:w-fit"
        >
          {t('page.search.trial_product_card.cta')}
        </Link>
      ) : (
        <div
          onClick={handlePaymentsModal}
          className="flex h-[30px] w-full cursor-pointer items-center justify-center rounded bg-primary-500 px-5 text-sm font-bold uppercase text-white hover:bg-primary-700 md:h-[40px] md:w-fit"
        >
          {t('page.search.trial_product_card.cta')}
        </div>
      )}
    </div>
  );
}
