import { useEffect, useState, useRef } from 'react';
import { useRouter } from 'next/router';

import { AnimatePresence, motion } from 'lib/framer-motion';
import { useOptionalUser } from 'hooks/useAuthState/useAuthState';
import { useWindowWidth } from 'hooks/useWindowWidth/useWindowWidth';
import { useDomEvent } from 'hooks/useDomEvent/useDomEvent';
import { getShowClasses } from 'components/pages/searchv2Page/SearchPage.utils';
import { FreeTrialBanner } from 'components/pages/searchv2Page/components/FreeTrialBanner/FreeTrialBanner';

import { SearchSidebar } from './components/SearchSidebar/SearchSidebar';
import { ClassSearchSidebar } from './components/SearchSidebar/ClassSearchSidebar';
import { SearchResults } from './components/SearchResults/SearchResults';

export function SearchPage() {
  const router = useRouter();
  const [showFreeTrialBanner, setShowFreeTrialBanner] = useState(false);
  const user = useOptionalUser();
  const hasAllAccess = user?.settings?.hasAllAccess || false;
  const windowWidth = useWindowWidth();
  const showFreeTrialBannerRef = useRef<HTMLDivElement>();

  const handleScroll = () => {
    const element = showFreeTrialBannerRef.current;

    if (element) {
      const elementHeight = element.offsetHeight || element.clientHeight;
      const elementDistance = element.getBoundingClientRect().top;

      if (elementDistance <= elementHeight) {
        setShowFreeTrialBanner(true);
      } else {
        setShowFreeTrialBanner(false);
      }
    }
  };

  useDomEvent(() => window, 'scroll', handleScroll, { passive: true });

  const { query } = useRouter();
  const showClasses = useRef(getShowClasses(query));

  // Disable default browser scroll position restoration
  // We don't preload previous search results so the position can't be restored properly
  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);

  //handle ai content search
  //keep this here to be passed to both search sidebar and results
  const [aiContentFilteredBy, setAiContentFilteredBy] = useState<string | undefined>(
    router.query?.aiContentType as string,
  );

  return (
    <div className="flex w-full justify-center bg-gray-bg">
      <div className="flex w-full flex-col items-center justify-center bg-gray-bg px-5 py-10 md:px-10 md:py-[30px]">
        <section className="mt-5 flex w-full flex-col gap-5 md:flex-row">
          <div className="w-full md:w-[285px] md:min-w-[285px]">
            {showClasses.current ? (
              <ClassSearchSidebar />
            ) : (
              <SearchSidebar filterBy={aiContentFilteredBy} setFilterBy={setAiContentFilteredBy} />
            )}
          </div>
          <div className="w-full">
            <SearchResults
              searchForClasses={showClasses.current}
              showFreeTrialRef={showFreeTrialBannerRef}
              contentFilteredBy={aiContentFilteredBy}
            />
          </div>
        </section>
        <AnimatePresence>
          {!hasAllAccess && showFreeTrialBanner ? (
            <motion.div
              animate={{ y: windowWidth && windowWidth < 768 ? '0%' : '-50%' }}
              initial={{ y: '100%' }}
              exit={{ y: '100%' }}
              transition={{ duration: 0.3, ease: 'easeInOut' }}
              className="fixed bottom-0 flex w-full max-w-[800px]"
            >
              <FreeTrialBanner />
            </motion.div>
          ) : null}
        </AnimatePresence>
      </div>
    </div>
  );
}
