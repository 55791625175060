import { useCallback, useEffect } from 'react';
import qs from 'qs';
import { useRouter } from 'next/router';
import algoliasearch from 'algoliasearch/lite';
import algoliaAnalytics from 'search-insights';
import { InstantSearch, useInstantSearch, Configure } from 'react-instantsearch';
import { createInsightsMiddleware } from 'instantsearch.js/es/middlewares';
import { useCookies } from 'react-cookie';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';

import {
  SEARCH_PAGE_COMMUNITY_CONTENT_ENABLED_TOGGLE_COOKIE_NAME,
  SEARCH_PAGE_AI_CONTENT_ENABLED_TOGGLE_COOKIE_NAME,
} from 'components/pages/searchPage/components/SearchSidebar/SearchSidebar';
import { getSearchState, searchStateToQuery } from 'components/pages/searchPage/SearchPage.utils';
import { AlgoliaContentHit } from 'components/elements/organisms/algoliaConnected/SearchResults/SearchResults.types';
import { ALLOWED_CONTENT_PRODUCTS_AUTHOR_IDS, QueryToSearchState } from 'components/pages/searchPage/SearchPage.utils';

import {
  productsAlgoliaAppId as algoliaAppId,
  productsAlgoliaApiKey as algoliaApiKey,
  productsAlgoliaIndex as algoliaIndex,
  contentAlgoliaIndex,
  contentAlgoliaIndexNewest,
  contentAlgoliaIndexOldest,
} from './InstantSearchProvider.utils';

export const searchClient = algoliasearch(algoliaAppId, algoliaApiKey);
const contentIndex = searchClient.initIndex(contentAlgoliaIndex);
const contentIndex_newest = searchClient.initIndex(contentAlgoliaIndexNewest);
const contentIndex_oldest = searchClient.initIndex(contentAlgoliaIndexOldest);

function InsightsMiddleware() {
  const { addMiddlewares } = useInstantSearch();

  useEffect(() => {
    const middleware = createInsightsMiddleware({
      insightsClient: algoliaAnalytics,
    });

    return addMiddlewares(middleware);
  }, [addMiddlewares]);

  return null;
}

const contentFilter = ALLOWED_CONTENT_PRODUCTS_AUTHOR_IDS.map(id => `authorId:${id}`).join(' OR ');

export const fetchContentData = async (
  query = '',
  page: number,
  hitsPerPage = 23,
  filterBy: string | undefined = undefined,
  sortBy = '',
) => {
  const facetFilters = filterBy ? [filterBy?.split(',').map(filter => `authorId:${filter}`)] : [];
  //fetching content data for new query
  const contentRequests = {
    hitsPerPage: hitsPerPage,
    maxValuesPerFacet: 999,
    filters: contentFilter,
    optionalFilters: '',
    page: page,
    query: query,
    facets: ['authorId'],
    facetFilters: facetFilters,
    tagFilters: [],
    highlightPostTag: '</span>',
    highlightPreTag: "<span style='font-weight: 900;'>",
  };

  if (sortBy.indexOf('newest') > -1) {
    const contentData = await contentIndex_newest.search<AlgoliaContentHit>(query, {
      ...contentRequests,
      clickAnalytics: true,
      headers: {
        accept: 'application/json',
      },
    });

    const queryId = contentData.queryID;

    return { ...contentData, hits: contentData.hits.map(hit => ({ ...hit, __queryID: queryId })) };
  }

  if (sortBy.indexOf('oldest') > -1) {
    const contentData = await contentIndex_oldest.search<AlgoliaContentHit>(query, {
      ...contentRequests,
      clickAnalytics: true,
      headers: {
        accept: 'application/json',
      },
    });

    const queryId = contentData.queryID;

    return { ...contentData, hits: contentData.hits.map(hit => ({ ...hit, __queryID: queryId })) };
  }

  const contentData = await contentIndex.search<AlgoliaContentHit>(query, {
    ...contentRequests,
    clickAnalytics: true,
    headers: {
      accept: 'application/json',
    },
  });

  const queryId = contentData.queryID;

  return { ...contentData, hits: contentData.hits.map(hit => ({ ...hit, __queryID: queryId })) };
};

export const useAlgoliaContentFetch = (variables: {
  page: number;
  query: string;
  sortBy: string;
  hitsPerPage?: number;
  enabled: boolean;
  filterBy?: string;
}) => {
  const { query, hitsPerPage, page, enabled, filterBy, sortBy } = variables;
  const queryClient = useQueryClient();

  const contentSortBy = sortBy.includes('newest')
    ? contentAlgoliaIndexNewest
    : sortBy.includes('oldest')
    ? contentAlgoliaIndexOldest
    : contentAlgoliaIndex;

  if (!enabled) {
    queryClient.removeQueries(['algoliaContentFetch.infinite']);
  }

  return useInfiniteQuery(
    ['algoliaContentFetch.infinite', query, filterBy, contentSortBy, page],
    ({ pageParam }) => {
      const toFetchPage = pageParam === undefined ? page : pageParam;

      return fetchContentData(query, toFetchPage, hitsPerPage, filterBy, contentSortBy);
    },
    {
      enabled,
      getNextPageParam: lastPage => {
        if (lastPage.nbPages > lastPage.page + 1) {
          return lastPage.page + 1;
        }

        return undefined;
      },
      getPreviousPageParam: firstPage => {
        if (firstPage.page > 0) {
          return firstPage.page - 1;
        }

        return undefined;
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchOnMount: false,
    },
  );
};

export const SearchPageProvider: React.FC<{ initialQuery: string }> = ({ children, initialQuery }) => {
  const router = useRouter();
  const [cookies] = useCookies([
    SEARCH_PAGE_COMMUNITY_CONTENT_ENABLED_TOGGLE_COOKIE_NAME,
    SEARCH_PAGE_AI_CONTENT_ENABLED_TOGGLE_COOKIE_NAME,
  ]);
  const isCommunityContentEnabled = cookies[SEARCH_PAGE_COMMUNITY_CONTENT_ENABLED_TOGGLE_COOKIE_NAME] === 'true';
  const isAiContentEnabled = cookies[SEARCH_PAGE_AI_CONTENT_ENABLED_TOGGLE_COOKIE_NAME] === 'true';

  const routerQuery = qs.stringify(router.query, { arrayFormat: 'comma' });
  const initialQueryParams = qs.parse(initialQuery);

  const searchState = getSearchState({
    url: routerQuery || initialQuery,
    isCommunityContentEnabled,
    isClassSearch: initialQueryParams.type === 'Classes',
    isAiContentEnabled,
  });

  const handleStateChange = useCallback(
    async ({ uiState, setUiState }) => {
      const stateToPass: QueryToSearchState = {
        uiState,
      };

      if (router.query.aiContentType) {
        stateToPass.other = {
          aiContentType: router.query.aiContentType,
        };
      }

      const query = searchStateToQuery(stateToPass);

      const newState = getSearchState({
        url: query,
        isCommunityContentEnabled,
        isClassSearch: initialQueryParams.type === 'Classes',
        isAiContentEnabled,
      });

      setUiState(newState.uiState);

      router.push({ query: query }, undefined, {
        scroll: false,
        shallow: true,
      });
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [router, isCommunityContentEnabled, isAiContentEnabled],
  );

  return (
    <InstantSearch
      initialUiState={searchState.uiState}
      onStateChange={handleStateChange}
      searchClient={searchClient}
      indexName={algoliaIndex}
    >
      <Configure filters={searchState.uiState[algoliaIndex].configure?.filters || ''} />
      {children}
      <InsightsMiddleware />
    </InstantSearch>
  );
};
