import { ClassNames } from '@emotion/react';
import { Breakpoint, useTheme, Theme } from '@mui/material';
import classNames from 'classnames';
import React, { Children, useEffect, useState } from 'react';
import ReactMasonry from 'react-masonry-component';

import styles from './Masonry.module.css';

type Columns = { [key in Breakpoint]?: number } & { default?: number };

function columnsToItemStyles(theme: Theme, columns: Columns, spacing: number) {
  const css: Record<string, string | Record<string, string>> = {};

  // we have a percentage based width _with_ a gutter, so the width is a little
  // bit confusing. the masonry library we use does not support percentages and gutter,
  // so we need to do the work and calculate the width ourselves. this is done by getting
  // the full width, subtracting the gutters, and then from there we can work
  // out of the column with by dividing this remaining value by the number of columns.
  // note: the amount of gutters is the number of columns - 1

  // so the formula is:
  // const widthWithoutGutters = 100% - (spacing * (columns - 1));
  // const itemWidth = widthWithoutGutters / columns;
  // in css this is:
  // calc((100% - (spacing * gutters)px) / numberOfColumns)
  function getCssWidthValue(value: number) {
    if (value === 1) {
      return '100%';
    }

    return `calc((100% - ${spacing * (value - 1)}px) / ${value})`;
  }

  if (columns.default) {
    css.width = getCssWidthValue(columns.default);
  }

  for (const [k, value] of Object.entries(columns)) {
    const key = k as keyof typeof columns;

    if (key === 'default') {
      continue;
    }

    css[theme.breakpoints.up(key)] = {
      width: getCssWidthValue(value),
    };
  }

  return css;
}

export function Masonry({
  columns = 3,
  children,
  spacing = 20,
  ...rest
}: {
  children: React.ReactNode;
  columns?: Columns | number;
  spacing?: number;
}) {
  const theme = useTheme();
  const itemStyles = columnsToItemStyles(theme, typeof columns === 'number' ? { default: columns } : columns, spacing);

  return (
    <ClassNames>
      {({ css }) => {
        const gridItemClassName = css(itemStyles, { marginBottom: spacing });

        return (
          <ReactMasonry
            options={{
              fitWidth: false,
              percentPosition: true,
              itemSelector: `.${styles.gridItem}`,
              transitionDuration: 0,
              gutter: spacing,
            }}
            {...rest}
          >
            {Children.map(children, child => {
              if (!child) {
                return null;
              }

              return (
                <div data-testid="masonry-child" className={classNames(gridItemClassName, styles.gridItem)}>
                  {child}
                </div>
              );
            })}
          </ReactMasonry>
        );
      }}
    </ClassNames>
  );
}

export function useCanShowMasonry() {
  const [showMasonry, setShowMasonry] = useState(false);

  useEffect(() => {
    setShowMasonry(true);
  }, []);

  return showMasonry;
}
