import React from 'react';
import { useCurrentRefinements } from 'react-instantsearch';

import { Divider } from 'components/elements/atoms/divider/Divider';

import { mapContentApiAuthorIdToSearchString } from '~/searchv2Page/components/SearchSidebar/SearchSidebar';

function Chip({ children, onRemove }: { children: React.ReactNode; onRemove: () => void }) {
  return (
    <div className="flex items-center gap-2 rounded border-[1px] border-gray-200 px-3 py-1 text-gray-700">
      <span>{children}</span>
      <button type="button" className="flex size-2 leading-[10px]" onClick={onRemove}>
        ×
      </button>
    </div>
  );
}

export function CurrentRefinements({
  contentFacets,
  filterBy,
  onRemoveFilter,
  onRemoveAllFilters,
}: {
  contentFacets: Record<string, number>;
  filterBy: string | undefined;
  onRemoveFilter: (filter: string) => void;
  onRemoveAllFilters: () => void;
}) {
  const { items, refine } = useCurrentRefinements();

  const filters = filterBy?.split(',') || [];

  if (items.length === 0 && filters.length === 0) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col items-start gap-2">
        <div className="flex w-full justify-between">
          <div className="text-sm font-bold">Selected Filters</div>
          <button type="button" className="whitespace-nowrap text-blue-500 underline" onClick={onRemoveAllFilters}>
            Clear all
          </button>
        </div>
        <div className="flex flex-wrap gap-2 self-start">
          {items.map(item =>
            item.refinements.map(refinement => (
              <Chip
                key={refinement.label}
                onRemove={() => {
                  refine(refinement);
                }}
              >
                {refinement.label === 'true' ? refinement.attribute : refinement.label}
              </Chip>
            )),
          )}
          {Object.entries(contentFacets)
            .filter(([id]) => filters.includes(id))
            .map(([id]) => (
              <Chip onRemove={() => onRemoveFilter(id)}>{mapContentApiAuthorIdToSearchString(id)}</Chip>
            ))}
        </div>
      </div>
      <Divider className="relative left-[-20px] w-[calc(100%+40px)]" />
    </>
  );
}
