import { useRouter } from 'next/router';
import { DEFAULT_LOCALE } from 'translations/locales';

import { AppRoute } from 'components/app/App.types';
import { Link } from 'components/elements/atoms/link/Link';
import { FutureImage } from 'components/elements/atoms/image/FutureImage';
import { useFormatMessage } from 'utilities/i18n';
import { getPathnameWithLocalePrefix } from 'components/app/App.utils';

import { SparkIgniteButton } from '~/sparkPage/components/sparkIgniteButton/SparkIgniteButton';

export function SparkBanner({ query }: { query: string }) {
  const t = useFormatMessage();
  const router = useRouter();

  return (
    <div
      style={{
        backgroundImage: `url("${process.env.NEXT_PUBLIC_IMAGE_URL}/spark-banner-cat-page-marketing.png")`,
      }}
      className="flex w-full flex-col items-center justify-between gap-[10px] bg-cover px-5 py-[10px] md:flex-row"
    >
      <Link
        underline="none"
        href={`${AppRoute.SPARK_CRYSTALLINE}${query.length > 0 ? `?pt=${query}` : ''}`}
        className="flex w-full items-center justify-between gap-[10px]"
      >
        <span className="flex items-center gap-[10px]">
          <FutureImage
            src={`${process.env.NEXT_PUBLIC_IMAGE_URL}/Spark-sub_fire.svg`}
            alt="Spark Logo"
            width={28}
            height={28}
          />
          <span className="font-bold text-white">{t('banners.search_page.spark.text')}</span>
        </span>
      </Link>
      <SparkIgniteButton
        href={getPathnameWithLocalePrefix(
          `${AppRoute.SPARK_CRYSTALLINE}${query.length > 0 ? `?pt=${query}` : ''}`,
          router.locale || DEFAULT_LOCALE,
        )}
        sx={{ width: { xs: '100%', md: 'fit-content' }, minWidth: '210px' }}
        state="idle"
      >
        {t('banners.search_page.spark.cta')}
      </SparkIgniteButton>
    </div>
  );
}
