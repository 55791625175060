import { Box, BoxProps, NativeSelect, NativeSelectProps, styled } from '@mui/material';

import { InputLabel } from 'components/elements/atoms/inputLabel/InputLabel';
import { Icon } from 'components/elements/atoms/icon/Icon';

export const BoxRoot = styled(Box, {
  shouldForwardProp: prop => !['fullWidth'].includes(prop as string),
})<BoxProps & { fullWidth?: boolean }>(({ theme, fullWidth }) => ({
  position: 'relative',
  display: 'inline-flex',
  ...(fullWidth && { width: '100%' }),
  '& .MuiInput-root': {
    ...(fullWidth && { width: '100%' }),
    '&::before, &::after': {
      display: 'none',
    },
  },
  '&:hover .MuiNativeSelect-filled': {
    borderColor: theme.palette.secondary.main,
    background: theme.palette.gray.extraLight,
  },
  '&:hover .MuiInputLabel-root': {
    color: theme.palette.secondary.main,
  },
}));

export const InputLabelStyled = styled(InputLabel)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1.25),
  left: theme.spacing(1.375),
  fontWeight: theme.typography.fontWeightSemiBold,
  fontSize: 13,
  transform: 'none',
  maxWidth: `calc(100% - ${theme.spacing(2.75)})`,
  color: theme.palette.grayPurple.dark,
  zIndex: theme.zIndex.appBar,
  '&.Mui-error': {
    color: theme.palette.grayPurple.dark,
  },
  '& .MuiFormLabel-asterisk.MuiFormLabel-asterisk': {
    color: 'inherit',
  },
}));

export const NativeSelectStyled = styled(NativeSelect, {
  shouldForwardProp: prop => !['isPlaceholderSelected', 'hiddenLabel'].includes(prop as string),
})<NativeSelectProps & { isPlaceholderSelected: boolean; hiddenLabel: boolean }>(
  ({ theme, isPlaceholderSelected, hiddenLabel }) => ({
    '& .MuiNativeSelect-outlined': {
      alignItems: 'center',
      height: theme.spacing(2.375),
      padding: theme.spacing(1.125, 4, 1.25, 1.875),
      border: `1px solid ${theme.palette.border.light}`,
      borderRadius: theme.spacing(0.625),
      background: theme.palette.common.white,
      fontSize: 13,
      color: theme.palette.gray.dark,
      ...(isPlaceholderSelected && { color: theme.palette.gray.light }),
    },
    '& .MuiNativeSelect-filled': {
      padding: theme.spacing(4.625, 1.25, 1.125),
      height: theme.spacing(2.25),
      background: theme.palette.gray.extraLight,
      borderRadius: theme.spacing(0.75),
      border: '1px solid transparent',
      transition: 'border linear .2s,box-shadow linear .2s',
      lineHeight: 1.2,
      ...(hiddenLabel && { padding: theme.spacing(1, 1.25, 1.25) }),
      ...(isPlaceholderSelected && { color: theme.palette.grayPurple.light }),
    },
    '&.Mui-focused .MuiNativeSelect-filled': {
      borderRadius: theme.spacing(0.75),
      background: theme.palette.gray.extraLight,
    },
    '&.Mui-error .MuiNativeSelect-filled': {
      borderColor: theme.palette.red.dark,
    },
  }),
);

export const IconOutlined = styled(Icon)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  fontSize: 12,
  pointerEvents: 'none',
}));

export const IconFilled = styled(Icon)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(1.375),
  right: theme.spacing(2.5),
  fontSize: 18,
  pointerEvents: 'none',
}));

export const IconValidation = styled(Icon)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  fontSize: 20,
  pointerEvents: 'none',
}));
