import classNames from 'classnames';
import React, { useState } from 'react';

export function Toggle({
  enabled: enabledFromProps,
  onToggle,
  children,
}: {
  enabled?: boolean;
  onToggle?: (enabled: boolean) => void;
  children?: React.ReactNode;
}) {
  const [enabled, setEnabled] = useState(enabledFromProps ?? false);

  const isControlled = typeof onToggle === 'function' && typeof enabledFromProps === 'boolean';
  const isEnabled = isControlled ? enabledFromProps : enabled;

  function onToggleProxy() {
    if (isControlled) {
      onToggle(!isEnabled);
    } else {
      setEnabled(!isEnabled);
    }
  }

  return (
    <>
      <label
        className={classNames(
          isEnabled ? 'bg-primary-700' : 'bg-gray-200',
          'relative inline-flex h-[20px] w-[36px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-700 focus:ring-offset-2',
        )}
      >
        <span className="sr-only">{children}</span>
        <span
          aria-hidden="true"
          className={classNames(
            isEnabled ? 'translate-x-[16px]' : 'translate-x-0',
            'pointer-events-none inline-block h-[16px] w-[16px] transform rounded-full bg-white shadow-md ring-0 transition-transform duration-200 ease-in-out',
          )}
        />
        <input type="checkbox" checked={isEnabled} onChange={onToggleProxy} className="sr-only" />
      </label>
    </>
  );
}
