import qs, { ParsedQs } from 'qs';
import type { UiState } from 'instantsearch.js';
import { ParsedUrlQuery } from 'querystring';

import { mapCategoryPageTypeToCategoryDetails } from 'components/pages/contentApi/pages/config';
import { STOCKPHOTOS_AUTHOR_ID } from 'components/pages/stockphotos/pages/config';
import {
  productsAlgoliaIndex,
  productsAlgoliaIndexNewest,
  productsAlgoliaIndexOldest,
} from 'components/contexts/instantSearchProvider/InstantSearchProvider.utils';

const SHOW_CLASSES_TYPE = 'Classes';

export const ALLOWED_CONTENT_PRODUCTS_AUTHOR_IDS = [
  ...Object.values(mapCategoryPageTypeToCategoryDetails).map(category => category.authorId),
  STOCKPHOTOS_AUTHOR_ID,
];

export const EXCLUDED_PRODUCTS_CATEGORIES_WHEN_AI_CONTENT_IS_DISABLED = [
  '"AI Illustrations"',
  '"AI Graphics"',
  '"AI Transparent PNGs"',
  '"AI Generated"',
  '"AI Patterns"',
  '"AI Coloring Pages"',
  '"AI Sketches"',
];

const indexToQuery: Record<string, string> = {
  [productsAlgoliaIndex]: '',
  [productsAlgoliaIndexNewest]: 'newest',
  [productsAlgoliaIndexOldest]: 'oldest',
};

const queryToIndex: Record<string, string> = {
  newest: productsAlgoliaIndexNewest,
  oldest: productsAlgoliaIndexOldest,
};

export const getShowClasses = (query: ParsedUrlQuery) => {
  if (query.type === SHOW_CLASSES_TYPE) {
    return true;
  }

  return Array.isArray(query.type) && query.type.includes(SHOW_CLASSES_TYPE);
};

export const getSearchState = ({
  url,
  isCommunityContentEnabled,
  isClassSearch = false,
  isAiContentEnabled,
}: {
  url: string;
  isCommunityContentEnabled: boolean;
  isClassSearch: boolean;
  isAiContentEnabled: boolean;
}) => {
  const queryObject = qs.parse(url);

  return queryToSearchState(queryObject, isCommunityContentEnabled, isClassSearch, isAiContentEnabled);
};

export type QueryToSearchState = {
  uiState: UiState;
  other?: {
    filter?: ParsedQsValue;
    aiContentType?: ParsedQsValue;
  };
};

export const queryToSearchState = (
  queryObject: ParsedQs,
  isCommunityContentEnabled: boolean,
  isClassSearch = false,
  isAiContentEnabled: boolean,
): QueryToSearchState => {
  const {
    query = '',
    page,
    sortBy,
    category,
    type,
    hasPod,
    hasPromotions,
    isFree,
    level,
    source,
    cfPremium,
    filter,
    aiContentType,
  } = queryObject;

  let filters = '';

  if (!isCommunityContentEnabled) {
    filters = 'NOT type:Community';
  }

  if (!isAiContentEnabled) {
    const excludeFilter = EXCLUDED_PRODUCTS_CATEGORIES_WHEN_AI_CONTENT_IS_DISABLED.join(' AND NOT category:');
    filters = filters.length > 0 ? `${filters} AND NOT category:${excludeFilter}` : `NOT category:${excludeFilter}`;
  }

  const queryType = decodeQueryArray(type)[0];

  if (queryType === 'Classes' && isClassSearch) {
    filters = filters.length > 0 ? `${filters} AND type:Classes` : 'type:Classes';
  }

  const state: UiState = {
    [productsAlgoliaIndex]: {
      query: decodeURIComponent(String(query)),
      page: !Number(page) ? 1 : Number(page),
      sortBy: sortBy ? queryToIndex[decodeURIComponent(String(sortBy))] || '' : '',
      configure: {
        hitsPerPage: 23,
        filters: filters,
      },
      refinementList: {
        category: decodeQueryArray(category),
        type: decodeQueryArray(type),
        hasPod: decodeQueryArray(hasPod),
        hasPromotions: decodeQueryArray(hasPromotions),
        'extraSettings.level': decodeQueryArray(level),
        'extraSettings.source': decodeQueryArray(source),
        'designer.premiumLegalEntity': decodeQueryArray(cfPremium),
        isFree: decodeQueryArray(isFree),
      },
    },
  };

  return {
    uiState: state,
    other: {
      filter: decodeQueryArray(filter),
      aiContentType: decodeQueryArray(aiContentType),
    },
  };
};

export const searchStateToQuery = (searchState: QueryToSearchState) => {
  const { query, page, refinementList, sortBy, configure } = searchState.uiState[productsAlgoliaIndex];
  const { category, type, hasPod, hasPromotions, isFree } = refinementList || {};

  const filteredByClasses = Boolean(type && type?.indexOf('Classes') !== -1);

  const isClassSearch =
    Boolean(configure?.filters) && !filteredByClasses && configure?.filters?.indexOf('type:Classes') !== -1;

  const filter = searchState.other?.filter;
  const aiContentType = searchState.other?.aiContentType;

  const queryObject = {
    filter: filter ? decodeQueryArray(filter) : undefined,
    query: query ? query : undefined,
    sortBy: sortBy ? indexToQuery[sortBy] : undefined,
    page: page && page !== 1 ? page : undefined,
    category: category ? category.map(encodeURIComponent) : [],
    aiContentType: aiContentType ? decodeQueryArray(aiContentType) : undefined,
    type: type ? type.map(encodeURIComponent) : isClassSearch ? [encodeURIComponent('Classes')] : [],
    hasPod: hasPod ? hasPod : [],
    hasPromotions: hasPromotions ? hasPromotions : [],
    cfPremium:
      refinementList && refinementList['designer.premiumLegalEntity']
        ? refinementList['designer.premiumLegalEntity'].map(decodeURIComponent)
        : [],
    isFree: isFree ? isFree : [],
    level:
      refinementList && refinementList['extraSettings.level']
        ? refinementList['extraSettings.level'].map(decodeURIComponent)
        : [],
    source:
      refinementList && refinementList['extraSettings.source']
        ? refinementList['extraSettings.source'].map(decodeURIComponent)
        : [],
  };

  return qs.stringify(queryObject, { arrayFormat: 'comma' });
};

type ParsedQsValue = undefined | string | string[] | ParsedQs | ParsedQs[];

export const decodeQueryArray = (value: ParsedQsValue): string[] => {
  if (typeof value === 'string') {
    if (value.length === 0) {
      return [];
    }

    if (value.indexOf(',') > -1) {
      return value.split(',').map(decodeURIComponent);
    }

    return [decodeURIComponent(value)];
  }

  if (value && Array.isArray(value)) {
    const stringValues: string[] = [];

    value.forEach((item: ParsedQsValue) => {
      if (typeof item === 'string') {
        stringValues.push(item);
      }
    });

    return stringValues.map(decodeURIComponent);
  }

  return [];
};
