import { useFormatMessage } from 'utilities/i18n';
import { Link } from 'components/elements/atoms/link/Link';
import { AppRoute } from 'components/app/App.types';
import { useSiteDataFetcher } from 'api/requests/site/site';
import { useOptionalUser } from 'hooks/useAuthState/useAuthState';

export function FreeTrialCard() {
  const t = useFormatMessage();
  const { data } = useSiteDataFetcher();
  const user = useOptionalUser();

  const enableStripeBilling = Boolean(data?.billing) && user !== null;

  const handlePaymentsModal = () => {
    if (typeof window.cfOpenPaymentsDialog === 'function') {
      window.cfOpenPaymentsDialog();
    }
  };

  if (!enableStripeBilling) {
    return (
      <Link
        underline="none"
        href={Boolean(data?.billing) ? AppRoute.B_FREE_TRIAL : AppRoute.FREE_TRIAL}
        color="inherit"
        className="flex flex-col items-center justify-center gap-[10px] rounded bg-primary-700 p-[10px] text-center font-bold text-white"
      >
        <span className="text-xl">{t('page.search.trial_product_card.title')}</span>
        <span className="mt-[5px] px-[10px] text-sm">{t('page.search.trial_product_card.copy')}</span>
        <span className="flex h-[40px] w-full items-center justify-center rounded bg-[#1b5ea8] px-5 text-base font-bold uppercase text-white">
          {t('page.search.trial_product_card.cta')}
        </span>
      </Link>
    );
  }

  return (
    <div
      onClick={handlePaymentsModal}
      className="flex cursor-pointer flex-col items-center justify-center gap-[10px] rounded bg-primary-700 p-[10px] text-center font-bold text-white"
    >
      <span className="text-xl">{t('page.search.trial_product_card.title')}</span>
      <span className="mt-[5px] px-[10px] text-sm">{t('page.search.trial_product_card.copy')}</span>
      <span className="flex h-[40px] w-full items-center justify-center rounded bg-[#1b5ea8] px-5 text-base font-bold uppercase text-white">
        {t('page.search.trial_product_card.cta')}
      </span>
    </div>
  );
}
