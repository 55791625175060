import { styled } from '@mui/material';

import { Button } from 'components/elements/atoms/button/Button';

import { SparkIgniteButtonState } from './SparkIgniteButton.types';

export const StyledButton = styled(Button)<{ state?: SparkIgniteButtonState }>(({ theme, state }) => ({
  '&.MuiButton-contained.secondaryColor':
    state === 'processing'
      ? {
          color: theme.palette.white.main,
          backgroundColor: theme.palette.red.light,
          '&:hover': {
            backgroundColor: theme.palette.red.light,
          },
          '&.loading': {
            backgroundColor: theme.palette.red.light,
          },
        }
      : {},
}));

export const Inner = styled('div')({
  position: 'relative',
  height: '100%',
});

export const IconWrapper = styled('span')({
  position: 'absolute',
  top: '0',
  height: '100%',
  transition: 'left 0.6s cubic-bezier(.2,.6,.01,.85)',
  textAlign: 'center',
});
