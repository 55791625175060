import { styled } from '@mui/material';
import { InputLabel as MuiInputLabel } from '@mui/material';

export const InputLabel = styled(MuiInputLabel)(({ theme }) => ({
  display: 'block',
  position: 'static',
  transform: 'none',
  fontSize: 12,
  marginBottom: theme.spacing(1),
  whiteSpace: 'normal',
  color: theme.palette.black.main,
  '&.Mui-disabled': {
    color: theme.palette.black.main,
  },
  '&.Mui-focused': {
    color: theme.palette.black.main,
  },
  '&.Mui-error': {
    color: theme.palette.red.dark,
  },
  '& + .MuiInputBase-root': {
    marginTop: 0,
  },
}));
