import { useState } from 'react';
import { useRefinementList } from 'react-instantsearch';
import classNames from 'classnames';

import { useFormatMessage } from 'utilities/i18n';

export enum ClassFilter {
  ALL = 'all',
  FREE = 'free',
  PAID = 'paid',
}

const BUTTON_FILTERS = [ClassFilter.ALL, ClassFilter.FREE, ClassFilter.PAID];

export function ClassIsFreeMenu() {
  const { refine, items } = useRefinementList({ attribute: 'isFree' });
  const t = useFormatMessage();

  const isFree = items.find(item => item.isRefined)?.value;
  const currentFilter = isFree === 'true' ? ClassFilter.FREE : isFree === 'false' ? ClassFilter.PAID : ClassFilter.ALL;

  const [isActive, setIsActive] = useState(currentFilter);

  const handleButtonClick = (filter: ClassFilter) => {
    if (isActive === filter) {
      return;
    }

    items?.forEach(item => {
      if (item.isRefined) {
        refine(item.value);
      }
    });
    setIsActive(filter);

    if (filter === ClassFilter.ALL) {
      return;
    }

    refine(filter === ClassFilter.FREE ? 'true' : 'false');
  };

  return (
    <div className="flex justify-evenly gap-[10px]">
      {BUTTON_FILTERS.map(filter => (
        <button
          onClick={() => handleButtonClick(filter)}
          className={classNames(
            isActive === filter ? 'bg-[#1e3892]' : 'bg-[#0177FB]',
            'h-[30px] w-full rounded font-semibold uppercase text-white hover:bg-[#1e3892]',
          )}
          key={filter}
        >
          {t(`component.sidebar.search_${filter}`)}
        </button>
      ))}
    </div>
  );
}
