import React, { ChangeEvent, useContext, useState } from 'react';

import { FormControlContext } from 'components/elements/atoms/formControl/FormControl';

import { SelectProps } from './Select.types';
import * as S from './Select.styles';

const PLACEHOLDER_VALUE = '';

export const Select = <Value extends string>(props: SelectProps<Value>) => {
  const { value, items, variant, onChange, className, placeholder, name, label, ...restProps } = props;
  const { error, disabled, fullWidth } = useContext(FormControlContext) || {};
  const [isPlaceholderSelected, setPlaceholderSelected] = useState(
    (typeof value === 'undefined' || value === PLACEHOLDER_VALUE) && Boolean(placeholder),
  );

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    if (onChange) {
      onChange(event.target.value as Value);
    }
    setPlaceholderSelected(false);
  };

  const renderIcon = () => {
    if (variant === 'filled') {
      if (error === true) {
        return <S.IconValidation color="red.dark" icon="exclamation-circle" />;
      }

      if (error === false) {
        return <S.IconValidation color="secondary.main" icon="check-circle" />;
      }

      return <S.IconFilled icon="caret-down" color="grayPurple.light" />;
    }

    return <S.IconOutlined icon="chevron-down" color="grayPurple.light" />;
  };

  return (
    <S.BoxRoot fullWidth={fullWidth}>
      {variant === 'filled' && <S.InputLabelStyled htmlFor={name}>{label}</S.InputLabelStyled>}
      <S.NativeSelectStyled
        value={value}
        IconComponent={renderIcon}
        onChange={handleChange}
        variant={variant}
        className={className}
        placeholder={placeholder}
        defaultValue={typeof value === 'undefined' ? PLACEHOLDER_VALUE : undefined}
        isPlaceholderSelected={isPlaceholderSelected}
        id={name}
        name={name}
        error={error}
        disabled={disabled}
        hiddenLabel={!label}
        {...restProps}
      >
        {placeholder && (
          <option value={PLACEHOLDER_VALUE} disabled>
            {placeholder}
          </option>
        )}
        {items.map(({ label: itemLabel, value: itemValue }) => (
          <option key={itemValue} value={itemValue}>
            {itemLabel}
          </option>
        ))}
      </S.NativeSelectStyled>
    </S.BoxRoot>
  );
};
