import { useCallback, useState, Dispatch, SetStateAction, useEffect } from 'react';
import { UseRefinementListProps, useInstantSearch, useSearchBox, useCurrentRefinements } from 'react-instantsearch';
import { useCookies } from 'react-cookie';
import { useRouter } from 'next/router';
import { stringify } from 'querystring';

import { CurrentRefinements } from 'components/pages/searchv2Page/components/CurrentRefinements/CurrentRefinements';
import { productsAlgoliaIndex as algoliaIndex } from 'components/contexts/instantSearchProvider/InstantSearchProvider.utils';
import { Toggle } from 'components/elements/atoms/toggle/Toggle';
import { SidebarFilter } from 'components/elements/organisms/algoliaConnected/SidebarFilter/SidebarFilter';
import { useFormatMessage } from 'utilities/i18n';
import { Divider } from 'components/elements/atoms/divider/Divider';
import { SearchInput } from 'components/pages/searchv2Page/components/SearchInput/SearchInput';
import { useSiteDataFetcher } from 'api/requests/site/site';
import { Checkbox } from 'components/elements/atoms/checkbox/Checkbox';
import { getMainDomain } from 'api/fetcher.utils';
import { useActiveSubscriptionTypes } from 'hooks/useHasSubscription/useHasSubscription';
import { useAlgoliaContentFetch } from 'components/contexts/instantSearchProvider/SearchPageProvider';
import { EXCLUDED_PRODUCTS_CATEGORIES_WHEN_AI_CONTENT_IS_DISABLED } from 'components/pages/searchv2Page/SearchPage.utils';
import { mapCategoryPageTypeToCategoryDetails } from 'components/pages/contentApi/pages/config';

export const mapContentApiAuthorIdToSearchString = (authorId: string) => {
  if (authorId === 'stockphotos') {
    return 'Stockphotos';
  }

  const data = Object.values(mapCategoryPageTypeToCategoryDetails).find(category => category.authorId === authorId);

  if (data) {
    return data.contentCategory;
  }
};

export enum SearchableFilters {
  PROMO = 'hasPromotions',
  CATEGORY = 'category',
  TYPE = 'type',
  HAS_POD = 'hasPod',
  PREMIUM = 'designer.premiumLegalEntity',
}

export const SEARCH_PAGE_COMMUNITY_CONTENT_ENABLED_TOGGLE_COOKIE_NAME =
  'search_results_include_community_content_react';

export const SEARCH_PAGE_AI_CONTENT_ENABLED_TOGGLE_COOKIE_NAME = 'search_results_include_ai_content_react';

export function SearchSidebar({
  filterBy,
  setFilterBy,
}: {
  filterBy: string | undefined;
  setFilterBy: Dispatch<SetStateAction<string | undefined>>;
}) {
  const router = useRouter();
  const { data: siteData } = useSiteDataFetcher();
  const { promotion } = siteData?.promo || {};
  const [cookies, setCookie] = useCookies([
    SEARCH_PAGE_COMMUNITY_CONTENT_ENABLED_TOGGLE_COOKIE_NAME,
    SEARCH_PAGE_AI_CONTENT_ENABLED_TOGGLE_COOKIE_NAME,
  ]);

  const [isCommunityContentEnabled, setIsCommunityContentEnabled] = useState(
    cookies[SEARCH_PAGE_COMMUNITY_CONTENT_ENABLED_TOGGLE_COOKIE_NAME] === 'true',
  );

  const [isAIContentEnabled, setIsAIContentEnabled] = useState(
    cookies[SEARCH_PAGE_AI_CONTENT_ENABLED_TOGGLE_COOKIE_NAME] === 'true',
  );

  useEffect(() => {
    if (!isAIContentEnabled) {
      setFilterBy(undefined);
    }
  }, [isAIContentEnabled, setFilterBy]);

  const activeSubscriptionTypes = useActiveSubscriptionTypes();

  const { setIndexUiState } = useInstantSearch();

  const t = useFormatMessage();

  const transformHasPodItems: UseRefinementListProps['transformItems'] = items => {
    return items.filter(item => item.label === 'true').map(item => ({ ...item, label: t('search.filter.pod') }));
  };

  //eslint-disable-next-line react-hooks/exhaustive-deps
  const memoedTransformHasPodItems = useCallback(transformHasPodItems, []);

  const transformPromoItems: UseRefinementListProps['transformItems'] = items => {
    return items.filter(item => item.label === 'true').map(item => ({ ...item, label: promotion?.name || '' }));
  };

  //eslint-disable-next-line react-hooks/exhaustive-deps
  const memoedTransformPromoItems = useCallback(transformPromoItems, []);

  const onCommunityContentToggle = (enabled: boolean) => {
    setIsCommunityContentEnabled(enabled);
    const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 365 * 10); // 10 years
    setCookie(SEARCH_PAGE_COMMUNITY_CONTENT_ENABLED_TOGGLE_COOKIE_NAME, String(enabled), {
      path: '/',
      expires,
      domain: getMainDomain(),
    });

    setIndexUiState(prevState => {
      const typeRefinementList = (prevState.refinementList && prevState.refinementList[SearchableFilters.TYPE]) || [];
      const categoryRefinementList =
        (prevState.refinementList && prevState.refinementList[SearchableFilters.CATEGORY]) || [];
      const resetType = typeRefinementList.length > 0 && typeRefinementList[0] === 'Community' && !enabled;
      const resetCategory = (typeRefinementList.length === 0 && !enabled) || resetType;

      return {
        ...prevState,
        refinementList: {
          ...prevState.refinementList,
          [SearchableFilters.TYPE]: resetType ? [] : typeRefinementList,
          [SearchableFilters.CATEGORY]: resetCategory ? [] : categoryRefinementList,
        },
      };
    });
  };

  const onAIContentToggle = (enabled: boolean) => {
    setIsAIContentEnabled(enabled);
    const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 365 * 10); // 10 years
    setCookie(SEARCH_PAGE_AI_CONTENT_ENABLED_TOGGLE_COOKIE_NAME, String(enabled), {
      path: '/',
      expires,
      domain: getMainDomain(),
    });

    setIndexUiState(prevState => {
      let filters = '';

      if (!isCommunityContentEnabled) {
        filters = 'NOT type:Community';
      }

      if (!enabled) {
        const excludeFilter = EXCLUDED_PRODUCTS_CATEGORIES_WHEN_AI_CONTENT_IS_DISABLED.join(' AND NOT category:');
        filters = filters.length > 0 ? `${filters} AND NOT category:${excludeFilter}` : `NOT category:${excludeFilter}`;
      }

      const categoryRefinementList =
        (prevState.refinementList && prevState.refinementList[SearchableFilters.CATEGORY]) || [];

      const filteredCategories = categoryRefinementList.filter(
        cat => !EXCLUDED_PRODUCTS_CATEGORIES_WHEN_AI_CONTENT_IS_DISABLED.includes(`"${cat}"`),
      );

      return {
        ...prevState,
        refinementList: {
          ...prevState.refinementList,
          [SearchableFilters.CATEGORY]: filteredCategories,
        },
        configure: {
          ...prevState.configure,
          filters,
        },
      };
    });

    const newQuery = { ...router.query };

    if (newQuery['aiContentType']) {
      delete newQuery['aiContentType'];

      router.push(
        {
          query: newQuery,
        },
        undefined,
        {
          scroll: false,
          shallow: true,
        },
      );
    }
  };

  //handle ai content search
  const { uiState } = useInstantSearch();
  const currentPage = uiState[algoliaIndex].page || 1;

  const shouldFetchContentData = isAIContentEnabled;

  const { query: algoliaQuery } = useSearchBox();

  const { data } = useAlgoliaContentFetch({
    page: currentPage - 1,
    query: algoliaQuery,
    enabled: shouldFetchContentData,
    sortBy: router.query.sortBy ? `${algoliaIndex}_${router.query.sortBy as string}` : algoliaIndex,
  });

  const handleContentRefinement = (authorId: string) => {
    const newQuery = { ...router.query };
    const filters = filterBy?.split(',') || [];

    if (filters.includes(authorId)) {
      filters.splice(filters.indexOf(authorId), 1);
    } else {
      filters.push(authorId);
      delete newQuery['page'];

      setIndexUiState(prevState => {
        return {
          ...prevState,
          page: 1,
        };
      });
    }

    if (filters.length === 0) {
      delete newQuery['aiContentType'];
    } else {
      newQuery['aiContentType'] = filters.join(',');
    }

    router.push(
      {
        query: newQuery,
      },
      undefined,
      {
        scroll: false,
        shallow: true,
      },
    );

    setFilterBy(filters.length === 0 ? undefined : filters.join(','));
  };

  const removeAllContentRefinements = () => {
    const newQuery = { ...router.query };
    delete newQuery['aiContentType'];
    delete newQuery['category'];
    delete newQuery['type'];
    delete newQuery['hasPod'];

    const url = new URL(window.location.href);
    url.search = stringify(newQuery);
    window.location.href = url.toString();
  };

  const contentFacets = data?.pages[0]?.facets?.authorId || {};

  const { items: currentRefinements } = useCurrentRefinements();

  const shouldHideProductData =
    filterBy !== undefined && isAIContentEnabled && Object.keys(currentRefinements).length === 0;

  return (
    <div className="bg-white">
      <h3 className="p-5 text-sm font-bold">{t('component.sidebar.search_and_filter')}</h3>
      <Divider thick />
      <div className="flex flex-col gap-5 p-5">
        <SearchInput />
        <Divider className="relative left-[-20px] w-[calc(100%+40px)]" />
        <CurrentRefinements
          contentFacets={contentFacets}
          filterBy={filterBy}
          onRemoveFilter={handleContentRefinement}
          onRemoveAllFilters={removeAllContentRefinements}
        />
        <div className="flex flex-row justify-between">
          <span className="text-sm font-bold">{t('search.filter.community_content')}</span>
          <Toggle enabled={isCommunityContentEnabled} onToggle={onCommunityContentToggle} />
        </div>
        <div className="flex flex-row justify-between">
          <span className="text-sm font-bold">{t('search.filter.ai_content')}</span>
          <Toggle enabled={isAIContentEnabled} onToggle={onAIContentToggle} />
        </div>
        <Divider className="relative left-[-20px] w-[calc(100%+40px)]" />
        {Boolean(promotion) ? (
          <SidebarFilter
            searchV2
            attribute={`${SearchableFilters.PROMO}`}
            header={t('search.filter.promo')}
            transformItems={memoedTransformPromoItems}
          >
            {({ items, handleRefine }) => (
              <div className="mt-5 flex flex-col">
                {items.map((item, index) => (
                  <div key={item.label + index} className="wrap flex items-center">
                    <Checkbox
                      searchV2
                      label={
                        <>
                          <span className="capitalize">{item.label}</span>
                          <span className="ml-[3px] text-xs text-gray-500">({item.count})</span>
                          {activeSubscriptionTypes.length === 0 && (
                            <span className="ml-[3px] text-red-500">
                              {t('common.discount', {
                                values: {
                                  percent: promotion?.discount,
                                },
                              })}
                            </span>
                          )}
                        </>
                      }
                      checked={item.isRefined}
                      onClick={() => handleRefine(item.value)}
                      className="!mr-0"
                    />
                  </div>
                ))}
              </div>
            )}
          </SidebarFilter>
        ) : null}
        <SidebarFilter searchV2 attribute={`${SearchableFilters.TYPE}`} header={t('search.filter.type')}>
          {({ items, handleRefine }) => (
            <div className="mt-5 flex flex-col">
              {items.map((item, index) => (
                <div key={item.label + index} className="wrap flex items-center">
                  <Checkbox
                    searchV2
                    label={
                      <>
                        <span className="capitalize">{item.label}</span>
                        <span className="ml-[3px] text-xs text-gray-500">({item.count})</span>
                      </>
                    }
                    checked={item.isRefined}
                    onClick={() => handleRefine(item.value)}
                  />
                </div>
              ))}
              {Object.entries(contentFacets).map(([id, count]) => (
                <div key={`content_author_id_${id}`} className="wrap flex items-center">
                  <Checkbox
                    key={filterBy}
                    searchV2
                    label={
                      <>
                        <span className="capitalize">{mapContentApiAuthorIdToSearchString(id)}</span>
                        <span className="ml-[3px] text-xs text-gray-500">({count})</span>
                      </>
                    }
                    checked={filterBy?.split(',').includes(id)}
                    onClick={() => handleContentRefinement(id)}
                  />
                </div>
              ))}
            </div>
          )}
        </SidebarFilter>
        {!shouldHideProductData && (
          <>
            <SidebarFilter
              searchV2
              attribute={`${SearchableFilters.CATEGORY}`}
              header={t('search.filter.categories')}
            />
            <SidebarFilter
              searchV2
              attribute={`${SearchableFilters.HAS_POD}`}
              header={t('search.filter.has_pod')}
              transformItems={memoedTransformHasPodItems}
            />
            <SidebarFilter
              searchV2
              attribute={`${SearchableFilters.PREMIUM}`}
              header={t('search.filter.cf_premium')}
              selectAll={router.query.filter === 'brands'}
              hideEndDivider={true}
            />
          </>
        )}
      </div>
    </div>
  );
}
