import React, { useContext } from 'react';

import { FormControlContext } from 'components/elements/atoms/formControl/FormControl';

import { InputLabelProps } from './InputLabel.types';
import * as S from './InputLabel.styles';

export const InputLabel = (props: InputLabelProps) => {
  const { children } = props;
  const { required, error, disabled } = useContext(FormControlContext) || {};

  if (!children) {
    return null;
  }

  return <S.InputLabel required={required} error={error} disabled={disabled} {...props} />;
};
