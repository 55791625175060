import { useCallback, useState, Dispatch, SetStateAction, useEffect } from 'react';
import classNames from 'classnames';
import { UseRefinementListProps, useInstantSearch, useSearchBox } from 'react-instantsearch';
import { useCookies } from 'react-cookie';
import { useRouter } from 'next/router';

import { productsAlgoliaIndex as algoliaIndex } from 'components/contexts/instantSearchProvider/InstantSearchProvider.utils';
import { Toggle } from 'components/elements/atoms/toggle/Toggle';
import { SidebarFilter } from 'components/elements/organisms/algoliaConnected/SidebarFilter/SidebarFilter';
import { useFormatMessage } from 'utilities/i18n';
import { Divider } from 'components/elements/atoms/divider/Divider';
import { SearchInput } from 'components/pages/searchPage/components/SearchInput/SearchInput';
import { useSiteDataFetcher } from 'api/requests/site/site';
import { Checkbox } from 'components/elements/atoms/checkbox/Checkbox';
import { getMainDomain } from 'api/fetcher.utils';
import { useActiveSubscriptionTypes } from 'hooks/useHasSubscription/useHasSubscription';
import { useAlgoliaContentFetch } from 'components/contexts/instantSearchProvider/SearchPageProvider';
import { EXCLUDED_PRODUCTS_CATEGORIES_WHEN_AI_CONTENT_IS_DISABLED } from 'components/pages/searchPage/SearchPage.utils';
import { mapCategoryPageTypeToCategoryDetails } from 'components/pages/contentApi/pages/config';

const mapContentApiAuthorIdToSearchString = (authorId: string) => {
  if (authorId === 'stockphotos') {
    return 'Stockphotos';
  }

  const data = Object.values(mapCategoryPageTypeToCategoryDetails).find(category => category.authorId === authorId);

  if (data) {
    return data.contentCategory;
  }
};

export enum SearchableFilters {
  PROMO = 'hasPromotions',
  CATEGORY = 'category',
  TYPE = 'type',
  HAS_POD = 'hasPod',
  PREMIUM = 'designer.premiumLegalEntity',
}

export const SEARCH_PAGE_COMMUNITY_CONTENT_ENABLED_TOGGLE_COOKIE_NAME =
  'search_results_include_community_content_react';

export const SEARCH_PAGE_AI_CONTENT_ENABLED_TOGGLE_COOKIE_NAME = 'search_results_include_ai_content_react';

export function SearchSidebar({
  filterBy,
  setFilterBy,
}: {
  filterBy: string | undefined;
  setFilterBy: Dispatch<SetStateAction<string | undefined>>;
}) {
  const router = useRouter();
  const { data: siteData } = useSiteDataFetcher();
  const { promotion } = siteData?.promo || {};
  const [cookies, setCookie] = useCookies([
    SEARCH_PAGE_COMMUNITY_CONTENT_ENABLED_TOGGLE_COOKIE_NAME,
    SEARCH_PAGE_AI_CONTENT_ENABLED_TOGGLE_COOKIE_NAME,
  ]);

  const [isCommunityContentEnabled, setIsCommunityContentEnabled] = useState(
    cookies[SEARCH_PAGE_COMMUNITY_CONTENT_ENABLED_TOGGLE_COOKIE_NAME] === 'true',
  );

  const [isAIContentEnabled, setIsAIContentEnabled] = useState(
    cookies[SEARCH_PAGE_AI_CONTENT_ENABLED_TOGGLE_COOKIE_NAME] === 'true',
  );

  useEffect(() => {
    if (!isAIContentEnabled) {
      setFilterBy(undefined);
    }
  }, [isAIContentEnabled, setFilterBy]);

  const activeSubscriptionTypes = useActiveSubscriptionTypes();

  const { setIndexUiState } = useInstantSearch();

  const t = useFormatMessage();

  const transformHasPodItems: UseRefinementListProps['transformItems'] = items => {
    return items.filter(item => item.label === 'true').map(item => ({ ...item, label: t('search.filter.pod') }));
  };

  //eslint-disable-next-line react-hooks/exhaustive-deps
  const memoedTransformHasPodItems = useCallback(transformHasPodItems, []);

  const transformPromoItems: UseRefinementListProps['transformItems'] = items => {
    return items.filter(item => item.label === 'true').map(item => ({ ...item, label: promotion?.name || '' }));
  };

  //eslint-disable-next-line react-hooks/exhaustive-deps
  const memoedTransformPromoItems = useCallback(transformPromoItems, []);

  const onCommunityContentToggle = (enabled: boolean) => {
    setIsCommunityContentEnabled(enabled);
    const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 365 * 10); // 10 years
    setCookie(SEARCH_PAGE_COMMUNITY_CONTENT_ENABLED_TOGGLE_COOKIE_NAME, String(enabled), {
      path: '/',
      expires,
      domain: getMainDomain(),
    });

    setIndexUiState(prevState => {
      const typeRefinementList = (prevState.refinementList && prevState.refinementList[SearchableFilters.TYPE]) || [];
      const categoryRefinementList =
        (prevState.refinementList && prevState.refinementList[SearchableFilters.CATEGORY]) || [];
      const resetType = typeRefinementList.length > 0 && typeRefinementList[0] === 'Community' && !enabled;
      const resetCategory = (typeRefinementList.length === 0 && !enabled) || resetType;

      return {
        ...prevState,
        refinementList: {
          ...prevState.refinementList,
          [SearchableFilters.TYPE]: resetType ? [] : typeRefinementList,
          [SearchableFilters.CATEGORY]: resetCategory ? [] : categoryRefinementList,
        },
      };
    });
  };

  const onAIContentToggle = (enabled: boolean) => {
    setIsAIContentEnabled(enabled);
    const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 365 * 10); // 10 years
    setCookie(SEARCH_PAGE_AI_CONTENT_ENABLED_TOGGLE_COOKIE_NAME, String(enabled), {
      path: '/',
      expires,
      domain: getMainDomain(),
    });

    setIndexUiState(prevState => {
      let filters = '';

      if (!isCommunityContentEnabled) {
        filters = 'NOT type:Community';
      }

      if (!enabled) {
        const excludeFilter = EXCLUDED_PRODUCTS_CATEGORIES_WHEN_AI_CONTENT_IS_DISABLED.join(' AND NOT category:');
        filters = filters.length > 0 ? `${filters} AND NOT category:${excludeFilter}` : `NOT category:${excludeFilter}`;
      }

      const categoryRefinementList =
        (prevState.refinementList && prevState.refinementList[SearchableFilters.CATEGORY]) || [];

      const filteredCategories = categoryRefinementList.filter(
        cat => !EXCLUDED_PRODUCTS_CATEGORIES_WHEN_AI_CONTENT_IS_DISABLED.includes(`"${cat}"`),
      );

      return {
        ...prevState,
        refinementList: {
          ...prevState.refinementList,
          [SearchableFilters.CATEGORY]: filteredCategories,
        },
        configure: {
          ...prevState.configure,
          filters,
        },
      };
    });

    const newQuery = { ...router.query };

    if (newQuery['aiContentType']) {
      delete newQuery['aiContentType'];

      router.push(
        {
          query: newQuery,
        },
        undefined,
        {
          scroll: false,
          shallow: true,
        },
      );
    }
  };

  const transformTypeItems: UseRefinementListProps['transformItems'] = items => {
    const itemsAreRefined = items.some(item => item.isRefined);

    if (!itemsAreRefined) {
      return items.map(item => ({ ...item }));
    }

    return items.filter(item => item.isRefined).map(item => ({ ...item }));
  };

  const memoedTransformTypeItems = useCallback(transformTypeItems, []);

  //handle ai content search
  const { uiState } = useInstantSearch();
  const currentPage = uiState[algoliaIndex].page || 1;
  const refined = uiState[algoliaIndex].refinementList || {};

  const shouldFetchContentData =
    isAIContentEnabled && Object.keys(refined).find(key => refined[key].length > 0) === undefined;

  const { query: algoliaQuery } = useSearchBox();

  const { data } = useAlgoliaContentFetch({
    page: currentPage - 1,
    query: algoliaQuery,
    enabled: shouldFetchContentData,
    filterBy,
    sortBy: router.query.sortBy ? `${algoliaIndex}_${router.query.sortBy as string}` : algoliaIndex,
  });

  const handleContentRefinement = (authorId: string) => {
    const newQuery = { ...router.query };

    if (filterBy === authorId) {
      delete newQuery['aiContentType'];
    } else {
      newQuery['aiContentType'] = authorId;
      delete newQuery['page'];

      setIndexUiState(prevState => {
        return {
          ...prevState,
          page: 1,
        };
      });
    }

    router.push(
      {
        query: newQuery,
      },
      undefined,
      {
        scroll: false,
        shallow: true,
      },
    );

    setFilterBy(prevValue => {
      if (prevValue === authorId) {
        return undefined;
      }

      return authorId;
    });
  };

  const contentFacets = data?.pages[0]?.facets?.authorId || {};

  const shouldHideProductData = filterBy !== undefined && isAIContentEnabled;

  return (
    <div className="bg-white">
      <h3 className="p-5 text-sm font-bold">{t('component.sidebar.search_and_filter')}</h3>
      <Divider thick />
      <div className="flex flex-col gap-5 p-5">
        <SearchInput />
        <Divider className="relative left-[-20px] w-[calc(100%+40px)]" />
        {!shouldHideProductData && (
          <>
            <div className="flex flex-row justify-between">
              <span className="text-sm font-bold">{t('search.filter.community_content')}</span>
              <Toggle enabled={isCommunityContentEnabled} onToggle={onCommunityContentToggle} />
            </div>
          </>
        )}
        <div className="flex flex-row justify-between">
          <span className="text-sm font-bold">{t('search.filter.ai_content')}</span>
          <Toggle enabled={isAIContentEnabled} onToggle={onAIContentToggle} />
        </div>
        <Divider className="relative left-[-20px] w-[calc(100%+40px)]" />
        {Boolean(promotion) && !shouldHideProductData ? (
          <SidebarFilter
            attribute={`${SearchableFilters.PROMO}`}
            header={t('search.filter.promo')}
            transformItems={memoedTransformPromoItems}
          >
            {({ items, handleRefine }) => (
              <div className="mt-5 flex flex-col">
                {items.map((item, index) => (
                  <div key={item.label + index} className="wrap flex items-center">
                    <Checkbox
                      label={
                        <>
                          <span className={classNames(item.isRefined && 'font-semibold', 'capitalize')}>
                            {item.label}
                          </span>
                          <span className="ml-[3px] text-xs text-gray-500">({item.count})</span>
                          {activeSubscriptionTypes.length === 0 && (
                            <span className="ml-[3px] text-red-500">
                              {t('common.discount', {
                                values: {
                                  percent: promotion?.discount,
                                },
                              })}
                            </span>
                          )}
                        </>
                      }
                      checked={item.isRefined}
                      onClick={() => handleRefine(item.value)}
                      className="!mr-0"
                    />
                  </div>
                ))}
              </div>
            )}
          </SidebarFilter>
        ) : null}
        <SidebarFilter
          attribute={`${SearchableFilters.TYPE}`}
          header={t('search.filter.type')}
          transformItems={memoedTransformTypeItems}
        >
          {({ items, handleRefine }) => (
            <div className="mt-5 flex flex-col">
              {items.map((item, index) => {
                if (shouldHideProductData) {
                  return null;
                }

                return (
                  <div key={item.label + index} className="wrap flex items-center">
                    <Checkbox
                      label={
                        <>
                          <span className={classNames(item.isRefined && 'font-semibold', 'capitalize')}>
                            {item.label}
                          </span>
                          <span className="ml-[3px] text-xs text-gray-500">({item.count})</span>
                        </>
                      }
                      checked={item.isRefined}
                      onClick={() => handleRefine(item.value)}
                    />
                  </div>
                );
              })}
              {Object.entries(contentFacets).map(item => {
                const id = item[0];
                const count = item[1];

                return (
                  <div key={`content_author_id_${id}`} className="wrap flex items-center">
                    <Checkbox
                      label={
                        <>
                          <span className="capitalize">{mapContentApiAuthorIdToSearchString(id)}</span>
                          <span className="ml-[3px] text-xs text-gray-500">({count})</span>
                        </>
                      }
                      checked={filterBy === id}
                      onClick={() => handleContentRefinement(id)}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </SidebarFilter>
        {!shouldHideProductData && (
          <>
            <SidebarFilter attribute={`${SearchableFilters.CATEGORY}`} header={t('search.filter.categories')} />
            <SidebarFilter
              attribute={`${SearchableFilters.HAS_POD}`}
              header={t('search.filter.has_pod')}
              transformItems={memoedTransformHasPodItems}
            />
            <SidebarFilter
              attribute={`${SearchableFilters.PREMIUM}`}
              header={t('search.filter.cf_premium')}
              selectAll={router.query.filter === 'brands'}
              hideEndDivider={true}
            />
          </>
        )}
      </div>
    </div>
  );
}
